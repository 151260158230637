import React from "react";
import Call from "./Call";

export default class Hero extends React.Component {
  render() {
    return (
      <div className="hero">
        <div className="container">
          <div className="row justify-content-start">
            <div className="col-12 col-md-7 col-lg-6 order-2 order-md-1">
              <div className="hero-text">{this.props.children}</div>

              <Call showButton />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
