import React from "react";

function LocationOverview() {
  return (
    <div>
      <div className="text-center mb-2">
        <h2 className="text-dark mb-2 visible">Standortübersicht</h2>
        <p>
          Sie finden mich gegenwärting an folgenden Standorten.
          <br /> Wo Ihre Therapie stattfindet, besprechen wir gemeinsam.
        </p>
      </div>

      <div className="row mt-4">
        <div className="col-12 col-lg-6 px-md-8">
          <LocationMap
            title="Moorbek Rondell Norderstedt"
            // image="/images/map-nor-rathausallee.png"
            address="Rathausallee 70, 22846 Norderstedt"
            addressExtra="in der logopädischen Praxis von Jennifer Gasch, 4. OG"
            googleMapsLink="https://goo.gl/maps/By7dZGFEva447QLF9"
          >
            Direkt an dem Busbahnhof und der Endhaltestelle der U1 (Richtung Hamburg) und der AKN (Richtung Henstedt-Ulzburg) sind die Praxisräume zentral gelegen und gut
            erreichbar. Parkmöglichkeiten sind in der Tiefgarage vorhanden. Diese Räumlichkeiten sind behindertengerecht ausgestattet.
          </LocationMap>
        </div>

        <div className="col-12 col-lg-6 mt-2 mt-lg-0 px-md-8">
          <LocationMap
            title="Quickborn"
            // image="/images/map-quickborn.png"
            address="Gerberstraße 6, 25451 Quickborn"
            addressExtra="in der logopädischen Praxis von Nicole Malewitsch, 1. OG"
            googleMapsLink="https://goo.gl/maps/3CoESFswFGeapAUv7"
          >
            Die Praxis ist zentral gelegen und gut an den öffentlichen Nahverkehr angeschlossen. Die AKN Haltestelle Quickborn ist ca. 10 Min Fußweg entfernt. Parkmöglichkeiten
            sind an der Straße vorhanden. Die Räumlichkeiten befinden sich im 1. OG und sind nicht behindertengerecht.
          </LocationMap>
        </div>
      </div>
    </div>
  );
}

const LocationMap = (props) => {
  return (
    <figure>
      <figcaption className="text-center">
        <h2>{props.title}</h2>

        {/* <img alt={"Karte " + props.address} className="img-fluid mt-1" src={props.image} /> */}

        <p className="mt-2">
          <strong>{props.address}</strong>
          <br />
          <small>{props.addressExtra}</small>
        </p>

        <a className="button px-2" href={props.googleMapsLink} target={"_blank"}>
          Routenplanung auf Google Maps
        </a>

        <p className="mt-2 text-justify">{props.children}</p>
      </figcaption>
    </figure>
  );
};

export default LocationOverview;
