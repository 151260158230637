import React from "react";
import Obfuscate from "react-obfuscate";
import { graphql, useStaticQuery } from "gatsby";

const Call = (props) => {
  const data = useStaticQuery(graphql`
    query ContactQuery {
      contactJson {
        phone
        email
        #contact_button_link
      }
    }
  `);
  return (
    <div className={"call " + (props.plain ? "plain" : "")}>
      <div className="call-box-top">
        {data.contactJson.phone && (
          <div className="call-phone">
            <strong>Telefon: </strong>
            <Obfuscate tel={`${withoutSpaces(data.contactJson.phone)}`}>{data.contactJson.phone}</Obfuscate>
          </div>
        )}
        {data.contactJson.email && (
          <div className="call-email">
            <strong>E-Mail: </strong>
            <Obfuscate email={data.contactJson.email} />
          </div>
        )}
      </div>
      {/* {props.showButton && (
        <div className="call-box-bottom">
          <a href={data.contactJson.contact_button_link} className="button">
            Kontakt
          </a>
        </div>
      )} */}
    </div>
  );
};

function withoutSpaces(str) {
  return str.replace(/\s/g, "");
}

export default Call;
